<template>
  <div>
    <v-card outlined class="mt-n3" tile>

      <v-toolbar flat class="">
        <div class="mt-5" align="left">

          <v-toolbar-title v-if="!showSearch"
            class="info--text text-uppercase font-weight-bold text--darken-1 justify-center">
            <v-icon class="mr-2 mt-n1" color="info">
              mdi-face
            </v-icon>
            Visitors Report
          </v-toolbar-title>

          <v-text-field v-else v-model="filterReportBySearch" prepend-icon="mdi-magnify" append-outer-icon="mdi-close"
            @click:append-outer="{ showSearch = !showSearch }" max-width="400px">
            <v-icon>
              mdi-close
            </v-icon>
          </v-text-field>

          <v-divider class="mx-4" vertical></v-divider>

        </div>
        <v-spacer></v-spacer>
        <div align="right">

          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-hover v-slot="{ hover }">
                <v-btn :class="{ 'blue--text': hover }" icon v-bind="attrs" v-on="on"
                  v-on:click="showSearch = !showSearch">
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </v-hover>
            </template>
            <span class="text-caption">Search</span>
          </v-tooltip>

          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-hover v-slot="{ hover }">
                <v-btn :class="{ 'blue--text': hover }" icon v-bind="attrs" v-on="on" @click="downloadCSV()">
                  <v-icon>mdi-cloud-download</v-icon>
                </v-btn>
              </v-hover>
            </template>
            <span class="text-caption">Download CSV</span>
          </v-tooltip>

          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-hover v-slot="{ hover }">
                <v-btn :class="{ 'blue--text': hover }" icon v-bind="attrs" v-on="on" onclick="window.print()">
                  <v-icon>mdi-printer</v-icon>
                </v-btn>
              </v-hover>
            </template>
            <span class="text-caption">Print</span>
          </v-tooltip>

          <v-menu :close-on-content-click="closeOnContentClick">
            <template v-slot:activator="{ on: menu, attrs }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                  <v-hover v-slot="{ hover }">
                    <v-btn :class="{ 'blue--text': hover }" icon v-bind="attrs" v-on="{ ...tooltip, ...menu }"
                      @click="closeOnContentClick = false">
                      <v-icon>mdi-view-column</v-icon>
                    </v-btn>
                  </v-hover>
                </template>
                <span class="text-caption">View Columns</span>
              </v-tooltip>
            </template>

            <v-card>
              <v-card-title class="pl-5">
                <span class="grey--text body-1">Show Columns</span>
                <v-spacer></v-spacer>
                <v-btn icon @click="closeOnContentClick = true">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-checkbox v-for="(header, index) in headers" :key="index" :label="`${header.text.toString()}`"
                  class="my-n2 pa-n5">
                </v-checkbox>
              </v-card-text>
            </v-card>
          </v-menu>

          <v-menu :close-on-content-click="closeOnContentClick">
            <template v-slot:activator="{ on: menu, attrs }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip }">
                  <v-hover v-slot="{ hover }">
                    <v-btn :class="{ 'blue--text': hover }" icon v-bind="attrs" v-on="{ ...tooltip, ...menu }"
                      @click="closeOnContentClick = false">
                      <v-icon>mdi-filter-variant</v-icon>
                    </v-btn>
                  </v-hover>
                </template>
                <span class="text-caption">Filter Table</span>
              </v-tooltip>
            </template>

            <v-card>
              <v-card-title class="pl-5">
                <span class="grey--text body-1">Filters</span>
                <v-spacer></v-spacer>
                <v-btn icon @click="closeOnContentClick = true">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-form class="pa-5">
                <v-row>
                  <v-col>
                    <v-select label="Host" :items="hosts" @change="onFilterSelect"></v-select>
                  </v-col>
                  <v-col>
                    <v-select label="Host Organization" :items="hostOrganization" @change="onFilterSelect"></v-select>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-dialog
                      ref="datePickerDialog"
                      v-model="datePickerModal"
                      :return-value.sync="filterDate"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="filterDate"
                          label="Date"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="filterDate"
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="datePickerModal = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="onDatePickerSelect"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                  <v-col>
                    <v-dialog
                      ref="dialog"
                      v-model="timePickerModal"
                      :return-value.sync="filterTime"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="filterTime"
                          label="Time"
                          prepend-icon="mdi-clock-time-four-outline"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="timePickerModal"
                        v-model="filterTime"
                        full-width
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="timePickerModal = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="onTimePickerSelect"
                        >
                          OK
                        </v-btn>
                      </v-time-picker>
                    </v-dialog>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-select label="Facility" :items="facility" @change="onFilterSelect"></v-select>
                  </v-col>
                  <v-col>
                    <v-select label="Guest" :items="guests" @change="onFilterSelect"></v-select>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-select label="Security" :items="watchmenList" item-text="attributes.name" item-value="id" @change="onFilterSelect" v-model="watchmanId"></v-select>
                  </v-col>
                  <v-col>
                  </v-col>
                </v-row>

              </v-form>
            </v-card>

          </v-menu>

        </div>
      </v-toolbar>

      <v-data-table 
        :headers="headersMap" 
        :items="listVisitors" 
        sort-by="name" 
        class="elevation-1"
        loading-text="Loading... Please wait" 
        :search="filterReportBySearch" 
        show-select 
        :single-select="singleSelect"
        v-model="selected"
      >
        <template v-slot:top>
          <v-progress-linear indeterminate color="info" v-if="setIsLoading"></v-progress-linear>
          <v-divider></v-divider>
        </template>

        <template v-slot:[`item.organization`]="{ item }">
          <span @click="selectOrganization(item)">{{ item.legal_name }}</span>
        </template>

        <template v-slot:[`item.status`]="{ item }">
          <div class="text-center">
            <v-chip v-if="item.checkin_time && item.checkout_time" pill color="orange" text-color="white">
              {{ item.visitor.status }}
            </v-chip>
            <v-chip v-if="item.checkin_time && !item.checkout_time" pill color="green" text-color="white">
              {{ item.visitor.status }}
            </v-chip>
            <v-chip v-if="!item.checkin_time && !item.checkout_time" pill color="info" text-color="white">
              {{ item.visitor.status }}
            </v-chip>
          </div>
        </template>

        <template v-slot:[`item.view_more`]="{ item }">
          <v-btn color="info" class="mx-1" fab x-small dark elevation="0" @click="viewVisit(item.id)">
            <v-icon>mdi-dots-horizontal</v-icon>
          </v-btn>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <div class="my-1 ml-auto">
            <v-btn color="info" class="mx-1" fab x-small dark elevation="0" @click="editItem(item)">
              <v-icon>mdi-pencil-outline</v-icon>
            </v-btn>
            <v-btn color="error" class="mx-1" fab x-small dark elevation="0" @click="deleteItem(item)">
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
          </div>
        </template>

        <template v-slot:no-data>
          <a class="info--text text--darken-1">
            No Reports
          </a>
        </template>
      </v-data-table>

    </v-card>

  </div>
</template>
<script>
import WatchmenService from '../../services/watchmen.service';

export default {
  data: () => ({
    name: "Reports",
    watchmenList: [],
    loading: false,
    timePickerModal: false,
    filterTime: null,
    showSearch: false,
    filterDate: null,
    datePickerModal: false,
    watchmanId: null,
    filterReportBySearch: null,
    closeOnContentClick: false,
    checkbox: true,
    items: [],
    headers: [],
    selectedHeaders: [],
    headersMap: [
      {
        text: "Visitor",
        align: "start",
        filterable: true,
        value: "visitor.name",
      },
      {
        text: "Host",
        value: "host.name",
      },
      {
        text: "CheckIn",
        value: "checkin_time",
      },
      {
        text: "CheckOut",
        value: "checkout_time",
      },
      {
        text: "Time",
        value: "duration",
      },
      {
        text: "Facility",
        value: "host_facility",
      },
      {
        text: "Reason",
        value: "reason",
      },
      {
        text: "Status",
        value: "status",
      },
      {
        text: "View",
        value: "view_more"
      }
    ],
    singleSelect: false,
    selected: [],
    csvData: [[4, 5, 6], [7, 8, 9]],

    selects: ['Foo', 'Bar', 'Fizz', 'Buzz'],

    // Filters
    hosts: [],
    hostOrganization: [],
    date: [],
    time: [],
    facility: [],
    guests: []
  }),

  computed: {

    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },

    visitorsList() {
      return this.$store.state.reports.reports
    },

    showHeaders() {
      return (this.headers).filter(header =>
        this.selectedHeaders.includes(header)
      );
    },

    listVisitors() {
      return this.$store.getters["reports/getAllReports"]
    },

    setIsLoading() {
      return this.$store.getters["reports/setIsLoading"];
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    startDateWatcher() {
      this.editedItem.start_date = this.formatDate(this.startDateWatcher);
    },
    clarificationDateWatcher() {
      this.editedItem.clarification_end_date = this.formatDate(
        this.clarificationDateWatcher
      );
    },
    evaluationDateWatcher() {
      this.editedItem.evaluation_end_date = this.formatDate(
        this.evaluationDateWatcher
      );
    },
    approvalDateWatcher() {
      this.editedItem.tender_approval_date = this.formatDate(
        this.approvalDateWatcher
      );
    },
  },

  created() {

    this.$nextTick(() => {
      this.getAllReports();
      this.filterTableVariables();
      this.getWatchmen();
    });

  },

  methods: {

    getWatchmen() {
      this.loading = true;
      WatchmenService.index().then(
        (response) => {
          if (response.status === 200) {
            this.watchmenList = response.data.data;
            this.loading = false;
          } else {
            this.watchmenList = [];
          }
        },
        (error) => {
          this.watchmenList = [];
          if (error.response.status === 401) {
            this.handleLogout();
          }
        }
      );
    },

    onTimePickerSelect() {
      this.$refs.dialog.save(this.filterTime);
      this.onFilterSelect(this.filterTime);
    },

    onDatePickerSelect() {
      this.$refs.datePickerDialog.save(this.filterDate);
      this.onFilterSelect(this.filterDate);
    },

    getAllReports() {
      return this.$store.dispatch('reports/list');
    },

    downloadCSV() {
      // let csv = 'Put,Column,Titles,Here\n';
      // this.listVisitors.forEach((row) => {
      //   csv += row.join(',');
      //   csv += "\n";
      // });
      //
      // const anchor = document.createElement('a');
      // anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
      // anchor.target = '_blank';
      // anchor.download = 'nameYourFileHere.csv';
      // anchor.click();
    },

    selectedColumn(column) {
      return this.selectedHeaders.includes(column)
    },

    viewVisit(item) {
      this.$router.push({
        name: `ViewReport`,
        params: { visitor: item }
      });
    },

    /**
     * Assign report filter with specific resources used in filtering
     */
    filterTableVariables() {
      if (this.listVisitors) {
        const hostSet = new Set();
        const dateSet = new Set();
        const hostOrganizationSet = new Set();
        const timeSet = new Set();
        const facilitySet = new Set();
        const guestsSet = new Set();

        this.listVisitors.forEach(item => {
          hostSet.add(item.host.name);
          dateSet.add(item.event_date);
          hostOrganizationSet.add(item.visitor.organization);
          timeSet.add(item.start_at);
          facilitySet.add(item.host_facility);
          guestsSet.add(item.visitor.name);
        })

        // convert set to array
        this.hosts = Array.from(hostSet);
        this.date = Array.from(dateSet);
        this.hostOrganization = Array.from(hostOrganizationSet);
        this.time = Array.from(timeSet);
        this.facility = Array.from(facilitySet);
        this.guests = Array.from(guestsSet);
      }
    },

    onFilterSelect(e) {
      return this.$store.dispatch('reports/filter', {
        payload: e,
        watchman_id: this.watchmanId,
      })
    }
  },

};
</script>